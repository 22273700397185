<template>
  <div>
    <v-row
      ><div class="display-1 pa-2 font-weight-light">
       Your History 
      </div></v-row
    >
   
    <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
    
    <v-card v-if="Array.isArray(tabledata) && tabledata.length" class="ma-3 pa-2">
     <v-row justify="end">
     <v-col cols="12" sm=3>
     <v-text-field
     style="padding:0px;margin:0px;"
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
          ></v-text-field>
     </v-col>
    <!--- <download-excel
              class="mx-5"
              :data="headers"
              :fields="tabledata"
              worksheet="Company_List"
              name="Company_List.xls"
            >
              <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>-->
            <v-btn  @click="exportexcel()" color="success" dark class="mx-5">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
     </v-row>
      <div style="width: 100%; overflow: auto">
            
            <v-data-table  id="exceltable"
              :headers="headers"
              :items="tabledata"
              class="elevation-1"
              :footer-props="{'items-per-page-options':[50, 100, -1]}"
              :search="search"
            >

          <template v-slot:item.company="{ item }">
          <v-simple-table>
                                                <thead>
                                                    <tr>
                                                      
                                                        <th class="text-left">Round </th>
                                                        <th class="text-left">Round Date </th>
                                                        <th class="text-left">Status </th>
                                                      
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    <tr v-for="it in item.company" :key="it.id">                                               
                                                        
                                                        <td v-if="it.companyOfferingLearnerSelection=='PLACED'">
                                                          <span style="color: green; font-weight: bold ">{{ it.round }}</span>
                                                            
                                                        </td>

                                                        
                                                        <td  v-else-if="it.status=='In-Process'">
                                                          <span style="color:orange; font-weight: bold ">{{ it.round }}</span>
                                                            
                                                        </td>

                                                        <td  v-else-if="it.status=='Rejected'">
                                                          <span style="color:red; font-weight: bold ">{{ it.round }}</span>
                                                            
                                                        </td>
                                                        <td v-else>
                                                          <span >{{ it.round }}</span>                                                    
                                                        </td>
                                                        <td v-if="it.companyOfferingLearnerSelection=='PLACED'">
                                                          <span  style="color: green; font-weight: bold"> {{ it.rounddate }}</span>                                                   
                                                        </td>
                                                      <td  v-else-if="it.status=='In-Process'">
                                                          <span  style="color: orange; font-weight: bold"> {{ it.rounddate }}</span>                                                  
                                                        </td>
                                                        <td  v-else-if="it.status=='Rejected'">
                                                          <span  style="color: red; font-weight: bold"> {{ it.rounddate }}</span>                                                  
                                                        </td>
                                                        <td v-else>
                                                          <span > {{ it.rounddate }}</span>
                                                          
                                                        </td>
                                                        
                                                        <td v-if="it.companyOfferingLearnerSelection=='PLACED'">
                                                          <span style="color: green; font-weight: bold">{{ it.status}}</span> 
                                                        </td>

                                                        <td  v-else-if="it.status=='In-Process'">
                                                          <span style="color: orange; font-weight: bold">{{ it.status}}</span> 
                                                        </td>
                                                        <td  v-else-if="it.status=='Rejected'">
                                                          <span style="color: red; font-weight: bold">{{ it.status}}</span> 
                                                        </td>
                                                        <td v-else>
                                                          <span >{{ it.status}}</span> 
                                                        </td>

                                                      
                                                        </tr>
                                                </tbody>
                                                </v-simple-table>
                                                </template>
          
            </v-data-table> 
              </div>
            </v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
              <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
              <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>

          </div>
</template>
<script>
import axios from "axios";
import Language from "../AdminMaster/Language.vue";
import {table2excel} from "../jquery.table2excel";
export default {
  components: { Language },
  data() {
    return {
      selorg: [],
      selay: [],
      orglist: [],
      aylist: [],
      grlist:[],
      snackbar_msg: "",
      grno:"",
      snackbar: false,
      color: "",
      learner_list:[],
      stud:"",
      data: [],
      tabledata: [],
      dataarray: [

       
      ],
      name:"",
      grnumber:"",
      org:"",
      email:"",
      mobile:"",
      year:"",
      program:"",
      loading: false,
      headers: [],
        headers: [
      { text: "Sr.No", value: "srno" }, 
       { text: "Company", value: "companyy[0]" },
      { text: "Round Details", value: "company" },   
    ],
      search: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
      exportexcel() {
     
        $("#exceltable").table2excel({
    
    name: "Worksheet Name",
    filename: "IndividualStudentReport", //do not include extension
    fileext: ".xls" // file extension
  }); 
   
    },
   
   
    init() {
        this.loading = true;
        axios
          .post("/PlacementReport/getindividualstudentreportstudent")
          .then((res) => {
            this.loading = false;
            if (res.data.msg == "200")
             {
                  this.tabledata = res.data.learnerlist;
                  this.name=res.data.name;
                  this.email=res.data.email;
                   this.mobile=res.data.mobile;
                   this.year=res.data.year;
                   this.program=res.data.program;
                   this.grnumber=res.data.grno;
                   this.org=res.data.org;
                   if(this.tabledata.length == 0){
                    this.showSnackbar("red", "Learner History Not Found");
                   }
            }else if(res.data.msg == "402"){
                   this.showSnackbar("red", res.data.error);
            }else if(res.data.msg == "NOTFOUNḌ"){
                   this.showSnackbar("red", "Learner Not Found");
            }
          });
      
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>